import Link from 'next/link';
import {
  IFooterCompanyProps,
  IFooterLinkProps,
} from '@views/components/app/Footer';
import { IBannerItemProps } from '@views/components/widget/BannerTop';

const isProd = process.env.NODE_ENV === 'production';
const HOST_URL = isProd ? process.env.HOST_URL : '';

export const BANNER_SIDES: IBannerItemProps[] = [
  {
    key: '_second_',
    src: `${HOST_URL}/assets/banner-2023/banner-06.jpg`,
    url: `${HOST_URL}/danh-muc/dich-vu-khac-dau-213.html`,
    srcSet: `${HOST_URL}/assets/banner-2023/banner-06.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-06.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_third_',
    src: `${HOST_URL}/assets/banner-2023/banner-07.jpg`,
    url: '#',
    srcSet: `${HOST_URL}/assets/banner-2023/banner-07.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-07.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },

  // {
  //   key: '_second_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 7_760x230px.jpg`,
  //   url: `${HOST_URL}/danh-muc/dich-vu-khac-dau-213.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 6_760x230px.jpg`,
  //   url: '#',
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
];

export const BANNER_MOBILE_SIDES: IBannerItemProps[] = [
  {
    key: '_second_',
    src: `${HOST_URL}/assets/banner-2023/banner-06.jpg`,
    url: `${HOST_URL}/bai-viet/chinh-sach-uu-dai`,
    srcSet: `${HOST_URL}/assets/banner-2023/banner-06.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-06.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-4/banner 6_760x230px.jpg`,
  //   url: '#',
  //   srcSet: `${HOST_URL}/assets/banner-4/banner 6_2280x690px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 6_2280x690px.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_second_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 7_760x230px.jpg`,
  //   url: `${HOST_URL}/danh-muc/dich-vu-khac-dau-213.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  {
    key: '_third_',
    src: `${HOST_URL}/assets/banner-2023/banner-07.jpg`,
    url: '#',
    srcSet: `${HOST_URL}/assets/banner-2023/banner-07.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-07.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
];

export const BANNER_ADS: IBannerItemProps = {
  key: '_four_',
  src: `${HOST_URL}/assets/banner-4/banner 8_1200x67px.jpg`,
  url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  srcSet: `${HOST_URL}/assets/banner-4/banner 8_2400x136px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 8_3600X204px.jpg 3x`,
  alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',

  // key: '_four_',
  // src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 8_1200X68px.jpg`,
  // url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  // srcSet: null,
  // alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
};

export const BANNER_MAINS: IBannerItemProps[] = [
  // {
  //   key: '_banner-sale1_',
  //   src: `${HOST_URL}/assets/banner-2023/30-04.jpg`,
  //   url: '#',
  //   // url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/30-04.jpg 2x, ${HOST_URL}/assets/banner-2023/30-04.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  {
    key: '_banner-sale03_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_0.jpg`,
    url: '#',
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_0.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_0.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_banner-sale02_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_1_1.jpg`,
    url: '#',
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_1_1.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_1_1.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_banner-sale01_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_1.jpg`,
    url: '#',
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_1.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_1.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_first_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_2.jpg`,
    url: `${HOST_URL}/danh-muc/giay-in-van-phong--photo-170.html`,
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_2.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_2.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_second_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_3.jpg`,
    url: `${HOST_URL}/danh-muc/giay-in-van-phong--photo-170.html`,
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_3.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_3.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  {
    key: '_third_',
    src: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_4.jpg`,
    url: `${HOST_URL}/danh-muc/giay-in-van-phong--photo-170.html`,
    srcSet: `${HOST_URL}/assets/banner-2023/06/banner_06_2023_4.jpg 2x, ${HOST_URL}/assets/banner-2023/06/banner_06_2023_4.jpg 3x`,
    alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  },
  // {
  //   key: '_banner_ink_',
  //   src: `${HOST_URL}/assets/banner-2023/banner-03.jpg`,
  //   url: `${HOST_URL}/danh-muc/dung-cu-van-phong-169.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner-03.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-03.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_second_',
  //   src: `${HOST_URL}/assets/banner-2023/banner-04.jpg`,
  //   // url: '#',
  //   url: `${HOST_URL}/danh-muc/but---muc-168.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner-04.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-04.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner-05.jpg`,
  //   // url: '#',
  //   url: `${HOST_URL}/danh-muc/thiet-bi-van-phong-396.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner-05.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-05.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner-08.jpg`,
  //   url: '#',
  //   // url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner-08.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-08.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner-09.jpg`,
  //   url: '#',
  //   // url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner-09.jpg 2x, ${HOST_URL}/assets/banner-2023/banner-09.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner_7.jpg`, 
  //   url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner_7.jpg 2x, ${HOST_URL}/assets/banner-2023/banner_7.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner_8.jpg`,
  //   url: '#',
  //   // url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner_8.jpg 2x, ${HOST_URL}/assets/banner-2023/banner_8.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-2023/banner_9.jpg`,
  //   url: '#',
  //   // url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
  //   srcSet: `${HOST_URL}/assets/banner-2023/banner_9.jpg 2x, ${HOST_URL}/assets/banner-2023/banner_9.jpg 3x`,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_banner_ink_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 2_1560x480px.jpg`,
  //   url: `${HOST_URL}/danh-muc/hop-muc-may-in-1209.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_first_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn_1560x480px.jpg`,
  //   url: `${HOST_URL}/danh-muc/but---muc-168.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_second_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 4_1560x480px.jpg`,
  //   url: `${HOST_URL}/danh-muc/dung-cu-van-phong-169.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
  // {
  //   key: '_third_',
  //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 3_1560x480px.jpg`,
  //   url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
  //   srcSet: null,
  //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
  // },
];

export const BANNER_MOBILE_MAINS: IBannerItemProps[] = BANNER_MAINS

// export const BANNER_MOBILE_MAINS: IBannerItemProps[] = [
//   {
//     key: '_banner-sale01_',
//     src: `${HOST_URL}/assets/banner-4/banner 1_780x240px.jpg`,
//     url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
//     srcSet: `${HOST_URL}/assets/banner-4/banner 1_1560x480px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 1_2340x720px.jpg 3x`,
//     alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   },
//   {
//     key: '_banner_ink_',
//     src: `${HOST_URL}/assets/banner-4/banner 3_780x240px.jpg`,
//     url: `${HOST_URL}/danh-muc/hop-muc-may-in-1209.html`,
//     srcSet: `${HOST_URL}/assets/banner-4/banner 3_1560x480px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 3_2340x720px.jpg 3x`,
//     alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   },
//   {
//     key: '_first_',
//     src: `${HOST_URL}/assets/banner-4/banner 2_780x240px.jpg`,
//     url: '#',
//     srcSet: `${HOST_URL}/assets/banner-4/banner 2_1560x480px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 2_2340x720px.jpg 3x`,
//     alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   },
//   {
//     key: '_second_',
//     src: `${HOST_URL}/assets/banner-4/banner 4_780x240px.jpg`,
//     url: `${HOST_URL}/danh-muc/dung-cu-van-phong-169.html`,
//     srcSet: `${HOST_URL}/assets/banner-4/banner 4_1560x480px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 4_2340x720px.jpg 3x`,
//     alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   },
//   {
//     key: '_third_',
//     src: `${HOST_URL}/assets/banner-4/banner 5_780x240px.jpg`,
//     url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
//     srcSet: `${HOST_URL}/assets/banner-4/banner 5_1560x480px.jpg 2x, ${HOST_URL}/assets/banner-4/banner 5_2340x720px.jpg 3x`,
//     alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   },

//   // {
//   //   key: '_banner_ink_',
//   //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 2_1560x480px.jpg`,
//   //   url: `${HOST_URL}/danh-muc/hop-muc-may-in-1209.html`,
//   //   srcSet: null,
//   //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   // },
//   // {
//   //   key: '_first_',
//   //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn_1560x480px.jpg`,
//   //   url: `${HOST_URL}/danh-muc/but---muc-168.html`,
//   //   srcSet: null,
//   //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   // },
//   // {
//   //   key: '_second_',
//   //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 4_1560x480px.jpg`,
//   //   url: `${HOST_URL}/danh-muc/dung-cu-van-phong-169.html`,
//   //   srcSet: null,
//   //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   // },
//   // {
//   //   key: '_third_',
//   //   src: `${HOST_URL}/assets/banner-autumn/bannerfast_midautumn 3_1560x480px.jpg`,
//   //   url: `${HOST_URL}/danh-muc/giay-in---giay-a4-165.html`,
//   //   srcSet: null,
//   //   alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
//   // },
// ];

export const BANNER_STICKY_AD: IBannerItemProps = {
  key: '_banner_ink_',
  src: `${HOST_URL}/assets/vertical-banner.jpg`,
  url: `${HOST_URL}/danh-muc/hop-muc-may-in-1209.html`,
  srcSet: `${HOST_URL}/assets/vertical-banner@2x.jpg 2x, ${HOST_URL}/assets/vertical-banner@3x.jpg 3x`,
  alt: 'Văn Phòng Phẩm Online ABC - Tối Ưu Chi Phí Thời Bão Giá',
};

export const FEATURE_LINKS = [
  {
    key: '_one_day_delivery_',
    imageSrc: `${HOST_URL}/assets/icon/icon-times.svg`,
    text: 'Giao hàng nhanh trong ngày',
  },
  {
    key: '_free_delivery_',
    imageSrc: `${HOST_URL}/assets/icon/icon-truck.svg`,
    text: 'Miễn phí giao hàng',
  },
  {
    key: '_discount_',
    imageSrc: `${HOST_URL}/assets/icon/icon-tag.svg`,
    text: 'Giảm giá',
  },
  {
    key: '_gift_',
    imageSrc: `${HOST_URL}/assets/icon/icon-gift.svg`,
    text: 'Quà tặng',
  },
  // {
  //   key: '_return_',
  //   imageSrc: `${HOST_URL}/assets/icon/icon-loop.svg`,
  //   text: 'Đổi trả nhanh chóng',
  // },
];

export const companyInfo: IFooterCompanyProps = {
  name: 'CÔNG TY TNHH TM DV ABC',
  address: (
    <>
      Trụ sở: 1174 Võ Văn Kiệt, Phường 10, Quận 5, TP. HCM <br />
      Địa chỉ kho: 671/3 Hồng Bàng, Phường 6, Quận 6, TP. HCM <br />
    </>
  ),
  phone: '0964 399 099',
  email: 'hcm@vanphongphamabc.com',
  link: 'https://vanphongphamabc.com',
  facebook: '',
  zalo: 'https://zalo.me/0964399099',
  copyright: (
    <>
      Copyright ⓒ 2013{' '}
      <Link href="/">
        <a>https://vanphongphamabc.com</a>
      </Link>
    </>
  ),
};

export const supportLinks: IFooterLinkProps[] = [
  {
    key: '_first_',
    url: `${HOST_URL}/bai-viet/huong-dan-mua-van-phong-pham`,
    text: 'Hướng dẫn mua hàng',
  },
  {
    key: '_second_',
    url: `${HOST_URL}/bao-gia`,
    text: 'Báo giá văn phòng phẩm',
  },
  {
    key: '_third_',
    url: `${HOST_URL}/bai-viet/chinh-sach-uu-dai`,
    text: 'Chính sách ưu đãi',
  },
  // {
  //   key: '_four_',
  //   url: `${HOST_URL}/bai-viet/chuong-trinh-giam-gia-5-15-qua-tang-van-phong-pham`,
  //   text: 'Chính sách chiết khấu',
  // },
  {
    key: '_five_',
    url: `${HOST_URL}/bai-viet/cau-hoi-thuong-gap-ve-van-phong-pham-faqs`,
    text: 'Câu hỏi thường gặp',
  },
  {
    key: '_six_',
    url: '//vanphongphamabc.com/blog',
    text: 'Blogs',
    external: true,
  },
];

export const policyLinks: IFooterLinkProps[] = [
  {
    key: '_first_',
    url: `${HOST_URL}/chinh-sach-bao-mat`,
    text: 'Chính sách bảo mật',
  },
  // {
  //   key: '_second_',
  //   url: '/bai-viet/huong-dan-mua-van-phong-pham',
  //   text: 'Chính sách mua hàng'
  // },
  // {
  //   key: '_third_',
  //   url: `${HOST_URL}/bai-viet/chinh-sach-va-n-chuyen-giao-hang-mien-phi-van-phong-pham`,
  //   text: 'Chính sách giao hàng',
  // },
  {
    key: '_four_',
    url: `${HOST_URL}/bai-viet/huong-dan-doi-tra-hang-van-phong-pham`,
    text: 'Chính sách đổi trả',
  },
  // {
  //   key: '_five_',
  //   url: '/',
  //   text: 'Chính sách thanh toán'
  // }
];

export const FREE_SHIP_TEXT = 'Miễn phí giao hàng HCM';
